@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --body-bg: 216, 19.1%, 26.7%;

    --color-primary: 198deg 93% 60%;
    --color-secondary: 198deg 93% 60%;
    --text-primary: 198deg 93% 60%;
    --text-secondary: 198deg 93% 60%;

    --bg-paper: 198deg 93% 60%;
    --text-paper: 198deg 93% 60%;
    --bg-main-viewport: 220, 39.3%, 11%;

    --card-radial-gradient-from: #e4e4e780;
    --card-radial-gradient-to: #e4e4e700;
  }

  body {
    @apply bg-zinc-100 text-zinc-900 dark:bg-zinc-950 dark:text-zinc-100;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --body-bg: 216, 22.1%, 66.7%;

      --color-primary: 198deg 93% 60%;
      --color-secondary: 198deg 93% 60%;
      --text-primary: 220, 14.3%, 95.9%;
      --text-secondary: 198deg 93% 60%;

      --bg-paper: 198deg 93% 60%;
      --text-paper: 198deg 93% 60%;
      --bg-main-viewport: 220, 39.3%, 11%;

      --card-radial-gradient-from: #19191b80;
      --card-radial-gradient-to: #19191b00;
    }

    body {
      @apply bg-zinc-950 text-zinc-100;
    }
  }

  ::selection {
    @apply bg-yellow-100 text-zinc-800;
  }

  label[data-required]::after {
    @apply pl-0.5 text-red-700 dark:text-red-300;
    @apply content-['*'];
  }
}

h1, h2, h3, h4, h5, h6, h7 {
   text-wrap: pretty;
}

strong {
  @apply text-zinc-900;
  @apply dark:text-white;
}
